import React, {Component} from "react";
import ReactDOM from "react-dom";

export class App extends Component {
    render() {
        return (
            <div className="container">
                TEST!!!!
                <ul>
                    <li>1223</li>
                </ul>
            </div>
        );
    }
}

export default App;

ReactDOM.render(<App/>, document.querySelector("#app"));